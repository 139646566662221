import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { renderErrorCreatePool } from "../../../../utils/validate";
import useStyles from "../../style";
import { alertFailure, alertSuccess } from "../../../../store/actions/alert";
import { useDispatch, useSelector } from "react-redux";
import { getErc20Contract, getWeb3Instance } from "../../../../services/web3";
import DepositCurrencyForm from "./DepositCurrencyForm";
import BigNumber from "bignumber.js";
import {
  ACCEPT_CURRENCY,
  BUSD_BSC_ADDRESS,
  NATIVE_TOKEN_ADDRESS,
  USDC_ADDRESS,
  USDC_BASE_ADDRESS,
  USDC_BSC_ADDRESS,
  USDC_DAO_ADDRESS,
  USDC_LINEA_ADDRESS,
  USDC_OKX_ADDRESS,
  USDC_POLYGON_ADDRESS,
  USDC_ZKSYNC_ADDRESS,
  USDT_ADDRESS,
  USDT_ARBITRUM_ADDRESS,
  USDT_AVALANCHE_ADDRESS,
  USDT_BASE_ADDRESS,
  WETH_BLAST_ADDRESS,
  USDT_BSC_ADDRESS,
  USDT_DAO_ADDRESS,
  USDT_LINEA_ADDRESS,
  USDT_OKX_ADDRESS,
  USDT_POLYGON_ADDRESS,
  CHAIN_ID_NAME_MAPPING,
  CHAIN_NAME_ID_MAPPING,
  USDC_AVALANCHE_ADDRESS,
  USDC_ARBITRUM_ADDRESS,
} from "../../../../constants";
import { ethers } from "ethers";

function DepositCurrency(props: any) {
  const classes = useStyles();
  const { poolDetail, watch } = props;
  const renderError = renderErrorCreatePool;
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [amount, setAmount] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [transactionHash, setTransactionHash] = useState<any>();
  const [contractBalance, setContractBalance] = useState<any>(0);
  const { currentNetworkId } = useSelector(
    (state: any) => state
  ).userCurrentNetwork;
  const networkAvailable = watch("networkAvailable");

  const dispatch = useDispatch();

  const { data: loginUser } = useSelector((state: any) => state.user);
  const [disableButton, setDisableButton] = useState<Boolean>(false);

  const openPopupCreate = (e: any) => {
    setIsOpenEditPopup(true);
  };

  function getChainNameById(chainId: string): string | null {
    return CHAIN_NAME_ID_MAPPING[chainId] || null;
  }

  const getCurrencyInfo = (network: string, currency: string) => {
    const info: any = {
      eth: {
        usdt: { address: USDT_ADDRESS, decimal: 6 },
        usdc: { address: USDC_ADDRESS, decimal: 6 },
      },
      bsc: {
        usdt: { address: USDT_BSC_ADDRESS, decimal: 18 },
        busd: { address: BUSD_BSC_ADDRESS, decimal: 18 },
        usdc: { address: USDC_BSC_ADDRESS, decimal: 18 },
      },
      polygon: {
        usdt: { address: USDT_POLYGON_ADDRESS, decimal: 6 },
        usdc: { address: USDC_POLYGON_ADDRESS, decimal: 6 },
      },
      avalanche: {
        usdt: { address: USDT_AVALANCHE_ADDRESS, decimal: 6 },
        usdc: { address: USDC_AVALANCHE_ADDRESS, decimal: 6 },
      },
      arbitrum: {
        usdt: { address: USDT_ARBITRUM_ADDRESS, decimal: 6 },
        usdc: { address: USDC_ARBITRUM_ADDRESS, decimal: 6 },
      },
      base: {
        usdt: { address: USDT_BASE_ADDRESS, decimal: 18 },
        usdc: { address: USDC_BASE_ADDRESS, decimal: 6 },
      },
      coredao: {
        usdt: { address: USDT_DAO_ADDRESS, decimal: 6 },
        usdc: { address: USDC_DAO_ADDRESS, decimal: 6 },
      },
      xlayer: {
        usdt: { address: USDT_OKX_ADDRESS, decimal: 6 },
        usdc: { address: USDC_OKX_ADDRESS, decimal: 6 },
      },
      zksync: {
        usdc: { address: USDC_ZKSYNC_ADDRESS, decimal: 6 },
      },
      linea: {
        usdt: { address: USDT_LINEA_ADDRESS, decimal: 6 },
        usdc: { address: USDC_LINEA_ADDRESS, decimal: 6 },
      },
      blast: {
        weth: { address: WETH_BLAST_ADDRESS, decimal: 18 },
      },
    };

    return info[network][currency];
  };

  useEffect(() => {
    const getContractBalance = async () => {
      try {
        const currencyDetail = getCurrencyInfo(
          poolDetail.network_available,
          poolDetail.accept_currency
        );
        const currencyAddress =
          poolDetail.accept_currency === ACCEPT_CURRENCY.ETH
            ? NATIVE_TOKEN_ADDRESS
            : currencyDetail.address;
        const currencyDecimal =
          poolDetail.accept_currency === ACCEPT_CURRENCY.ETH
            ? 18
            : currencyDetail.decimal;
        const erc20Contract =
          currencyAddress !== NATIVE_TOKEN_ADDRESS
            ? getErc20Contract({
                networkAvailable: poolDetail.network_available,
                erc20TokenAddress: currencyAddress,
              })
            : undefined;
        const contractBalance = await erc20Contract?.methods
          .balanceOf(poolDetail.campaign_hash)
          .call();
        const rawContractBalance =
          currencyAddress === NATIVE_TOKEN_ADDRESS
            ? new BigNumber(contractBalance)
                .times(new BigNumber(10).pow(currencyDecimal))
                .toFixed()
            : new BigNumber(contractBalance).toFixed();
        const currencyBalance = new BigNumber(rawContractBalance)
          .div(new BigNumber(10).pow(currencyDecimal))
          .toFixed();
        setContractBalance(currencyBalance);
      } catch (error) {}
    };
    if (poolDetail?.campaign_hash) {
      getContractBalance();
    }
  }, [poolDetail, refetch]);

  useEffect(() => {
    if (poolDetail.total_sold_coin) {
      setAmount(poolDetail.total_sold_coin);
    }
  }, [poolDetail, poolDetail.token, poolDetail.network_available]);

  const handleCreateUpdateData = async () => {
    const chainId = getChainNameById(currentNetworkId);
    setRefetch(false);
    const currencyDetail = getCurrencyInfo(
      poolDetail.network_available,
      poolDetail.accept_currency
    );
    const currencyAddress =
      poolDetail.accept_currency === ACCEPT_CURRENCY.ETH
        ? NATIVE_TOKEN_ADDRESS
        : currencyDetail.address;
    const currencyDecimals =
      poolDetail.accept_currency === ACCEPT_CURRENCY.ETH
        ? 18
        : currencyDetail.decimal;

    if (chainId !== networkAvailable) {
        dispatch(alertFailure(`Please Switch Network to ${networkAvailable}`));
        return;
    }
    if (amount === "") {
      dispatch(alertFailure(`Please Enter Amount`));
      return;
    }
    if (!poolDetail) {
      dispatch(alertFailure(`Token Details not found`));
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Do you want buy deposit currency?")) {
      return false;
    }
    try {
      setLoading(true);
      setDisableButton(true);
      const weiValue: any = ethers.utils.parseUnits(
        String(amount),
        currencyDecimals
      );
      let hash;

      if (currencyAddress === NATIVE_TOKEN_ADDRESS) {
        const web3 = getWeb3Instance();
        if (!web3) throw new Error("Fail to connect account");
        hash = await web3.eth.sendTransaction({
          from: loginUser?.wallet_address,
          to: poolDetail?.campaign_hash,
          value: weiValue,
        });
      } else {
        const ercContract = getErc20Contract({
          networkAvailable: poolDetail?.network_available,
          erc20TokenAddress: currencyAddress,
        });
        if (!ercContract) throw new Error("Contract not found");
        hash = await ercContract.methods
          .transfer(poolDetail.campaign_hash, weiValue)
          .send({
            from: loginUser.wallet_address,
          });
      }
      setTransactionHash(hash.transactionHash);

      setLoading(false);
      dispatch(alertSuccess("Deposit Success !!!"));
      setIsOpenEditPopup(false);
      setRefetch(true);
    } catch (e) {
      setDisableButton(false);
      setLoading(false);
      dispatch(alertFailure("Deposit Fail !!!"));
      setIsOpenEditPopup(false);
      setRefetch(false);
      return false;
    }
  };

  return (
    <>
      {isOpenEditPopup && (
        <DepositCurrencyForm
          isOpenEditPopup={isOpenEditPopup}
          setIsOpenEditPopup={setIsOpenEditPopup}
          renderError={renderError}
          setAmount={setAmount}
          amount={amount}
          handleCreateUpdateData={handleCreateUpdateData}
          disableButton={disableButton}
          loading={loading}
        />
      )}
      <br />
      <div>
        <label className={classes.exchangeRateTitle}>Deposit Currency</label>
      </div>
      <label className={classes.formControlLabel}>
        Contract Balance:{" "}
        {!isNaN(Number(contractBalance)) ? contractBalance : "0"}{" "}
        {poolDetail?.accept_currency}
      </label>
      <br />

      <div className={`${classes.formControl} ${classes.flexRow}`}>
        <Button variant="contained" color="primary" onClick={openPopupCreate}>
          Deposit Currency
        </Button>
      </div>
      {transactionHash && <div>Transaction Hash: {transactionHash}</div>}
    </>
  );
}

export default DepositCurrency;

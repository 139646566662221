import React from 'react';
import useStyles from "../style";
import {renderErrorCreatePool} from "../../../utils/validate";

function PoolRKPDynamicRate(props: any) {
  const classes = useStyles();
  const {
    register, errors, 
    poolDetail, isEditStakingPool
  } = props;
  const renderError = renderErrorCreatePool;

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>UnLocked Point Rate</label>
        <input
          type="number"
          name="unlocked_point_rate"
          defaultValue={poolDetail?.unlocked_point_rate}
          min={0}
          ref={register()}
          className={classes.formControlInput}
          disabled = {isEditStakingPool}
        />
        <p className={classes.formErrorMessage}>
          {
            renderError(errors, 'unlocked_point_rate')
          }
        </p>
      </div>
    </>
  );
}

export default PoolRKPDynamicRate;
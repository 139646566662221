

import React, { useEffect, useState } from "react";
import useStyles from "../../style";
import { renderErrorCreatePool } from "../../../../utils/validate";
import { DatePicker } from "antd";
import moment from "moment";
import { Controller } from "react-hook-form";
import { useCommonStyle } from "../../../../styles";
import { DATETIME_FORMAT, UTC_TIMEZONE } from "../../../../constants";

function FirstTimeClaimPhase(props: any) {
  const classes = useStyles();
  const commonStyle = useCommonStyle();
  const { register, setValue, errors, watch, getValues, poolDetail, control } =
    props;
  const renderError = renderErrorCreatePool;
  const isDeployed = !!poolDetail?.is_deploy;

  useEffect(() => {
    if (poolDetail) {
      // First time claim phase
      if (poolDetail.first_time_claim_phase) {
        setValue(
          "first_time_claim_phase",
          moment(poolDetail.first_time_claim_phase, DATETIME_FORMAT)
        );
      }
    }
  }, [poolDetail]);

  function handleDateSelection(field: any, date: any, validationObj: any = {}) {
    const utcDate = date.toISOString();
    setValue(field, moment(utcDate), validationObj);
  }

  const renderUtcSpan = () =>
    UTC_TIMEZONE && (
      <span style={{ fontWeight: "bold", fontSize: "12px" }}>UTC</span>
    );

  const [isInitailDateShift, setIsInitailDateShift] = useState({
    first_time_claim_phase: false,
  });


  return (
    <>
      <div className={classes.formControlFlexBlock}>
        <label className={classes.formControlLabel}>1st claim phase</label>
        {renderUtcSpan()}
        <div style={{ marginBottom: 25 }}>
          <Controller
            control={control}
            rules={{
              required: false,
              validate: {
                // greaterOrEqualToday: (value) => {
                //   if (isDeployed || isBuyTypeFCFS) return true;
                //   console.log(value);
                //   return new Date(value) >= new Date();
                // },
              },
            }}
            name="first_time_claim_phase"
            render={(field) => {
              const selectedDate = field.value ? new Date(field.value) : null;
              return (
                <DatePicker
                  {...field}
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                    format: "HH:mm",
                  }}
                  onSelect={(datetimeSelected) => {
                    if (
                      UTC_TIMEZONE &&
                      !getValues("first_time_claim_phase") &&
                      !isInitailDateShift.first_time_claim_phase
                    ) {
                      handleDateSelection(
                        field.name,
                        moment(datetimeSelected).add(1, "day"),
                        {
                          shouldValidate: true,
                        }
                      );
                      setIsInitailDateShift((prevState) => ({
                        ...prevState,
                        first_time_claim_phase: true,
                      }));
                    } else {
                      handleDateSelection(field.name, datetimeSelected, {
                        shouldValidate: true,
                      });
                    }
                  }}
                  // onSelect={(datetimeSelected: any) => {
                  //   setValue(field.name, datetimeSelected, { shouldValidate: true });
                  // }}
                  value={
                    selectedDate
                      ? UTC_TIMEZONE
                        ? moment(selectedDate).utc()
                        : moment(selectedDate)
                      : null
                  }
                  inputReadOnly={UTC_TIMEZONE}
                  minuteStep={5}
                  className={`${commonStyle.DateTimePicker} ${classes.formDatePicker}`}
                  // disabled={isDeployed}
                />
              );
            }}
          />
        </div>
        <div
          className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}
        >
          {renderError(errors, "first_time_claim_phase")}
        </div>
      </div>
    </>
  );
}

export default FirstTimeClaimPhase;
